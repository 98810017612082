<script setup lang="ts">
import { useEvents } from '~/api/drupal'
import { EventsDocument } from '~/api/graphql/generated'

useHead({
  bodyAttrs: {
    class: 'events-listing',
  },
})

const { t } = useI18n()

const filter = {
  options: {
    type: [
      { id: 'conference', name: t('event.list.filters.eventType.conference'), parent: '' },
      { id: 'info', name: t('event.list.filters.eventType.info'), parent: '' },
    ],
    location: [
      {
        value: '1',
        label: t('event.list.filters.locationPhysical'),
      },
      {
        value: '0',
        label: t('event.list.filters.locationOnline'),
      },
    ],
  },
  values: reactive({
    type: ref<string[]>([]),
    location: ref<string[]>(['1', '0']),
  }),
}

const parseType = (type: string[]) => {
  if (type.includes('conference') && type.includes('info')) return {}
  if (type.includes('conference'))
    return {
      isConferenceEvent: ['1'],
      isInfoEvent: ['0'],
    }
  if (type.includes('info'))
    return {
      isConferenceEvent: ['0'],
      isInfoEvent: ['1'],
    }
  return {}
}

const request = {
  document: EventsDocument,
  cacheKey: 'events',
  createVariables: () => ({
    ...parseType(filter.values.type),
    ...(filter.values.location.length ? { physical: filter.values.location } : {}),
  }),
}

const { years, page, pages, noResults } = await useEvents(filter, request)
</script>

<template>
  <Events
    v-model:filter="filter.values"
    v-model:page="page"
    :text="{
      title: $t('event.list.title'),
      location: $t('event.list.filters.location'),
      noResults: $t('event.list.noResults'),
    }"
    :options="filter.options"
    :years="years"
    :pages="pages"
    :no-results="noResults"
  />
</template>
